<template>
  <b-card class="card-wrapper">
    <b-container v-if="project.objectID">
      <b-row>
        <b-col>
          <b-form inline @submit.prevent>
            <b-form-input
              class="search"
              type="search"
              size="sm"
              v-model="searchText"
              :placeholder="'Search ' + dbref + '...'"
            />
          </b-form>
        </b-col>
        <b-col class="text-right">
          <b-button
            variant="primary"
            size="sm"
            class="mr-1 new-buttons"
            :to="{ name: dbref + '-new', query: { path: pathString } }"
            ><i class="fas fa-plus"></i> {{ newButtonLabel }}</b-button
          >
          <b-button variant="primary" size="sm" v-b-modal="'newFolderModal'"
            ><i class="fas fa-folder"></i> New Folder</b-button
          >

          <b-modal
            id="newFolderModal"
            title="New Folder"
            ok-title="Create"
            ok-variant="primary"
            @ok="createNewFolder"
          >
            <b-form-group label="Name">
              <b-form-input
                type="text"
                v-model="newFolder.name"
                placeholder="e.g. New Folder"
              />
            </b-form-group>
            <b-form-group label="Description">
              <b-form-textarea
                v-model="newFolder.description"
                placeholder="e.g. This is a description of the new folder..."
                rows="4"
                max-rows="4"
              ></b-form-textarea>
            </b-form-group>
          </b-modal>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mt-3 w-100">
          <b-alert
            show
            variant="secondary"
            class="d-flex justify-content-left w-100"
          >
            <div class="alert-message">
              <b-link class="text-dark" @click="goHome"
                ><i class="fas fa-home"></i
              ></b-link>
              <span v-for="(breadcrumb, index) in path" :key="index">
                <i class="fas fa-angle-right mx-2 mt-1"></i>
                <b-link
                  class="text-dark"
                  @click="goToBreadcrumb(breadcrumb, index)"
                  >{{ breadcrumb.name }}</b-link
                >
              </span>
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            hover
            striped
            responsive
            :fields="fields"
            :items="searchList"
          >
            <template #cell(select)="data">
              <b-form-checkbox
                v-model="selected"
                :value="data.index"
                v-if="!data.item.isFolder"
              >
              </b-form-checkbox>
            </template>
            <template #cell(icon)="data">
              <b-link
                v-if="data.item.isFolder"
                class="font-weight-bolder"
                @click="openFolder(data.item)"
              >
                <i class="fas fa-folder"></i>
              </b-link>
            </template>
            <template #cell(name)="data">
              <b-link
                v-if="data.item.isFolder"
                class="font-weight-bolder"
                @click="openFolder(data.item)"
              >
                {{ data.item.name }}
              </b-link>
              <span v-if="!data.item.isFolder">
                {{ data.item.name }}
              </span>
            </template>
            <template
              v-for="slotName in Object.keys($scopedSlots)"
              v-slot:[slotName]="slotScope"
            >
              <slot :name="slotName" v-bind="slotScope"></slot>
            </template>
            <template #cell(actions)="data">
              <slot name="index_actions" v-bind="data"></slot>
              <b-button
                variant="primary"
                size="sm"
                class="ml-1"
                :to="{
                  name: dbref + '-edit',
                  params: { id: data.item.key },
                  query: { path: pathString },
                }"
                v-if="!data.item.isFolder"
                ><i class="fas fa-edit"></i
              ></b-button>
              <b-button
                variant="primary"
                size="sm"
                class="ml-1"
                @click="showEditFolder(data.item)"
                v-if="data.item.isFolder"
                ><i class="fas fa-edit"></i
              ></b-button>
              <b-button
                variant="danger"
                size="sm"
                class="ml-1"
                v-if="!data.item.isFolder"
                v-b-modal="'confirmDelete' + data.item.key"
                ><i class="far fa-trash-alt"></i
              ></b-button>
              <b-button
                variant="danger"
                size="sm"
                class="ml-1"
                v-if="data.item.isFolder"
                v-b-modal="'confirmFolderDelete' + data.item.key"
                ><i class="far fa-trash-alt"></i
              ></b-button>

              <b-modal
                :id="'editFolder' + data.item.key"
                title="Edit Folder"
                ok-title="Save"
                ok-variant="primary"
                @ok="saveExistingFolder(data.item)"
              >
                <b-form-group label="Name">
                  <b-form-input
                    type="text"
                    v-model="saveFolder.name"
                    placeholder="e.g. New Folder"
                  />
                </b-form-group>
                <b-form-group label="Description">
                  <b-form-textarea
                    v-model="saveFolder.description"
                    placeholder="e.g. This is a description of the new folder..."
                    rows="4"
                    max-rows="4"
                  ></b-form-textarea>
                </b-form-group>
              </b-modal>

              <b-modal
                :id="'confirmDelete' + data.item.key"
                :title="'Delete ' + title"
                ok-title="Delete"
                ok-variant="danger"
                @ok="deleteEntity(data.item)"
              >
                Are you sure you want to delete {{ title.toLowerCase() }}
                {{ data.item.name }}? This cannot be undone.
              </b-modal>

              <b-modal
                :id="'confirmFolderDelete' + data.item.key"
                :title="'Delete Folder ' + title"
                ok-title="Delete"
                ok-variant="danger"
                @ok="deleteFolder(data.item.key)"
              >
                Are you sure you want to delete folder
                {{ title.toLowerCase() }} {{ data.item.name }}? All items within
                the folder will also be deleted. This cannot be undone.
              </b-modal>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            variant="primary"
            size="sm"
            class="mr-1"
            v-b-modal="'moveModal'"
            :disabled="selected.length === 0"
            ><i class="far fa-folder-open"></i> Move</b-button
          >
          <b-button
            variant="primary"
            size="sm"
            class="mr-1"
            v-b-modal="'duplicateModal'"
            :disabled="selected.length === 0"
            ><i class="far fa-copy"></i> Duplicate</b-button
          >
          <b-button
            variant="danger"
            size="sm"
            v-b-modal="'deleteModal'"
            :disabled="selected.length === 0"
            ><i class="far fa-trash-alt"></i> Delete</b-button
          >

          <b-modal
            id="moveModal"
            title="Move To"
            ok-title="Move Here"
            ok-variant="primary"
            @show="moveTo = pathString"
            @ok="moveEntities"
          >
            <p class="mb-3">
              Select the destination to move the selected items:
            </p>
            <folder-selector
              :dbref="dbref"
              :project-key="project.objectID"
              v-model="moveTo"
            ></folder-selector>
          </b-modal>

          <b-modal
            id="duplicateModal"
            title="Duplicate To"
            ok-title="Duplicate Here"
            ok-variant="primary"
            @show="duplicateTo = pathString"
            @ok="duplicateEntities"
          >
            <p class="mb-3">
              Select the destination to duplicate the selected items:
            </p>
            <folder-selector
              :dbref="dbref"
              :project-key="project.objectID"
              v-model="duplicateTo"
            ></folder-selector>
          </b-modal>

          <b-modal
            id="deleteModal"
            title="Delete"
            ok-title="Delete"
            ok-variant="danger"
            @ok="bulkDelete"
          >
            The selected items will be permanently deleted. Are you sure you
            wish to continue?
          </b-modal>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-else>
      <b-alert variant="info" show
        ><div class="alert-message">
          <i class="fas fa-exclamation-circle"></i> Please select a project.
        </div></b-alert
      >
    </b-container>
  </b-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import FolderSelector from "@/components/FolderSelector";
import slugify from "slugify";

export default {
  name: "HierarchicalIndex",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    FolderSelector,
  },
  props: {
    dbref: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    newButtonLabel: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    searchFields: {
      type: Array,
      required: false,
      default: () => {
        return ["name", "description"];
      },
    },
  },
  data() {
    return {
      project: {},
      folder: {},
      items: {},
      root: ["folders", "", ""],
      path: [],
      newFolder: {
        name: "",
        description: "",
      },
      saveFolder: {
        key: "",
        name: "",
        description: "",
      },
      searchText: "",
      selected: [],
      moveTo: "",
      duplicateTo: "",
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    ...mapGetters(["userDisplayName", "userAvatar"]),
    folderList() {
      if (this.folder) {
        return "folders" in this.folder
          ? Object.keys(this.folder.folders).map((folder) => {
              return {
                key: folder,
                isFolder: true,
                ...this.folder.folders[folder],
              };
            })
          : [];
      } else {
        return [];
      }
    },
    itemList() {
      if (this.folder) {
        return "items" in this.folder
          ? Object.keys(this.folder.items).map((item) => {
              return { key: item, isFolder: false, ...this.folder.items[item] };
            })
          : [];
      } else {
        return [];
      }
    },
    combinedList() {
      return [...this.folderList, ...this.itemList];
    },
    searchList() {
      if (this.searchText.length > 0) {
        const searchText = this.searchText.toLowerCase();
        return Object.keys(this.items)
          .map((item) => {
            return { key: item, isFolder: false, ...this.items[item] };
          })
          .filter((item) => {
            let match = false;
            this.searchFields.forEach((field) => {
              if (item[field].toLowerCase().includes(searchText)) {
                match = true;
              }
            });
            return match;
          });
      } else {
        return this.combinedList;
      }
    },
    userUid() {
      return this.userProfile.uid;
    },
    topLevel() {
      return this.root.length === 3;
    },
    pathString() {
      let path = "/" + this.dbref;
      if (this.path.length > 0) {
        path +=
          "/" +
          this.path
            .map((breadcrumb) => {
              return breadcrumb.slug;
            })
            .join("/");
      }
      return path;
    },
  },
  watch: {
    userUid: {
      immediate: true,
      handler(uid) {
        this.bindObject("selected", uid, "project");
      },
    },
    project: {
      immediate: true,
      handler(project) {
        this.root[1] = this.dbref;
        if (project.objectID) {
          this.root[2] = project.objectID;
          console.log("Project", project.objectID, this.$route.params.path);
          if (this.$route.params.path !== undefined) {
            this.setRootFromPath(this.$route.params.path).then(() => {
              this.bindObject(this.root.join("/"), null, "folder");
            });
          } else {
            this.bindObject(this.root.join("/"), null, "folder");
          }
          this.bindObject(this.dbref, project.objectID, "items");
        }
      },
    },
    root: {
      immediate: true,
      handler(root) {
        this.root[1] = this.dbref;
        this.bindObject(root.join("/"), null, "folder");
      },
    },
    path: {
      immediate: false,
      handler() {
        history.replaceState({}, null, this.pathString);
      },
    },
  },
  methods: {
    goHome() {
      this.root = ["folders", this.dbref, this.project.objectID];
      this.path = [];
      this.selected = [];
    },
    goBack() {
      this.root.splice(-2, 2);
      this.path.pop();
      this.selected = [];
    },
    openFolder(folder) {
      console.log(folder);
      this.root.push("folders", folder.key);
      this.path.push({
        name: folder.name,
        slug: folder.slug,
        root: [...this.root],
      });
      this.selected = [];
    },
    goToBreadcrumb(breadcrumb, index) {
      console.log(breadcrumb);
      this.root = breadcrumb.root;
      this.path.splice(index, this.path.length - index);
      this.selected = [];
    },
    async getRootFromPath(routePath) {
      console.log("Route Path: " + routePath);
      const segments = routePath.split("/");
      let root = ["folders", this.dbref, this.project.objectID];
      let path = [];
      for (const segment of segments) {
        const snapshot = await this.getObjectSnapshot(
          root.join("/"),
          null,
          true
        );
        const folder = snapshot.val();
        console.log("Folder", folder);
        if (folder && "folders" in folder) {
          Object.keys(folder.folders).forEach((subkey) => {
            const subfolder = folder.folders[subkey];
            if (subfolder.slug === segment.toLowerCase()) {
              root.push("folders", subkey);
              path.push({
                name: subfolder.name,
                slug: subfolder.slug,
                root: [...root],
              });
            }
          });
        }
      }
      return { root: [...root], path: [...path] };
    },
    async setRootFromPath(routePath) {
      console.log("Route Path: " + routePath);
      const segments = routePath.split("/");
      let root = ["folders", this.dbref, this.project.objectID];
      let path = [];
      for (const segment of segments) {
        const snapshot = await this.getObjectSnapshot(
          root.join("/"),
          null,
          true
        );
        const folder = snapshot.val();
        console.log("Folder", folder);
        if (folder && "folders" in folder) {
          Object.keys(folder.folders).forEach((subkey) => {
            const subfolder = folder.folders[subkey];
            if (subfolder.slug === segment.toLowerCase()) {
              root.push("folders", subkey);
              path.push({
                name: subfolder.name,
                slug: subfolder.slug,
                root: [...root],
              });
            }
          });
        }
      }
      this.root = [...root];
      this.path = [...path];
    },
    deleteEntity(item) {
      const key = item.key;
      const path = "path" in item ? item.path : this.root.join("/") + "/items";
      Promise.all([
        this.deleteObject(this.dbref + "/" + this.project.objectID, key),
        this.deleteObject(path, key),
      ])
        .then(() => {
          this.notyf.success(this.title + " deleted successfully.");
        })
        .catch((error) => {
          this.notyf.error(
            "An error occurred deleting the " + this.title.toLowerCase() + "."
          );
          console.error(this.title + " delete failed", key, error);
        });
    },
    bulkDelete() {
      const indexes = [...this.selected];
      const path = this.root.join("/") + "/items";
      const items = [...this.searchList];
      if (indexes.length > 0) {
        const promises = [];
        for (const index of indexes) {
          const item = { ...items[index] };
          promises.push(
            this.deleteObject(
              this.dbref + "/" + this.project.objectID,
              item.key
            ),
            this.deleteObject(path, item.key)
          );
        }
        Promise.all(promises)
          .then(() => {
            this.selected = [];
            this.notyf.success("Selected items deleted successfully.");
          })
          .catch((error) => {
            this.notyf.error("An error occurred deleting the selected items.");
            console.error("Bulk delete failed", error);
          });
      } else {
        this.notyf.error("No items are selected");
      }
    },
    async moveEntities() {
      const indexes = [...this.selected];
      const rootPath = await this.getRootFromPath(this.moveTo);
      const path = rootPath.root.join("/") + "/items";
      const items = [...this.searchList];
      if (indexes.length > 0) {
        const promises = [];
        for (const index of indexes) {
          const item = { ...items[index] };
          const key = item.key;
          delete item.key;
          const existingPath = item.path;
          item.path = path;

          promises.push(
            this.deleteObject(existingPath, key),
            this.updateObject(path, key, item)
          );
        }
        Promise.all(promises)
          .then(() => {
            this.selected = [];
            this.notyf.success("Selected items moved successfully.");
          })
          .catch((error) => {
            this.notyf.error("An error occurred moving the selected items.");
            console.error("Move failed", error);
          });
      } else {
        this.notyf.error("No items are selected");
      }
    },
    async duplicateEntities() {
      const indexes = [...this.selected];
      const rootPath = await this.getRootFromPath(this.duplicateTo);
      const path = rootPath.root.join("/") + "/items";
      const items = [...this.searchList];
      if (indexes.length > 0) {
        const promises = [];
        for (const index of indexes) {
          const item = { ...items[index] };
          const key = await this.createObjectKey();
          delete item.key;
          item.path = path;

          promises.push(
            this.updateObject(
              this.dbref + "/" + this.project.objectID,
              key,
              item
            ),
            this.updateObject(path, key, item)
          );
        }
        Promise.all(promises)
          .then(() => {
            this.selected = [];
            this.notyf.success("Selected items duplicated successfully.");
          })
          .catch((error) => {
            this.notyf.error(
              "An error occurred duplicating the selected items."
            );
            console.error("Duplicate failed", error);
          });
      } else {
        this.notyf.error("No items are selected");
      }
    },
    createNewFolder() {
      let newFolder = { ...this.newFolder };
      newFolder.slug = this.getUniqueSlug(newFolder.name);

      this.createObject(this.root.join("/") + "/folders", newFolder)
        .then(() => {
          this.notyf.success("Folder created successfully.");
          this.newFolder = { name: "", description: "" };
        })
        .catch((error) => {
          this.notyf.error("An error occurred creating the folder.");
          console.error("Folder create failed", error);
        });
    },
    getUniqueSlug(name) {
      let slug = slugify(name, { lower: true, strict: true });
      let modifier = 2;
      let folderSlugs = this.folderList.map((folder) => {
        return folder.slug;
      });
      while (folderSlugs.includes(slug)) {
        modifier++;
        slug = slug + "-" + modifier;
      }
      return slug;
    },
    showEditFolder(folder) {
      this.saveFolder = {
        name: folder.name,
        description: folder.description,
      };
      this.$bvModal.show("editFolder" + folder.key);
    },
    saveExistingFolder(folder) {
      let updated = {
        name: this.saveFolder.name,
        description: this.saveFolder.description,
      };

      this.updateObject(this.root.join("/") + "/folders", folder.key, updated)
        .then(() => {
          this.notyf.success("Folder saved successfully.");
        })
        .catch((error) => {
          this.notyf.error("An error occurred saving the folder.");
          console.error("Folder save failed", folder.key, error);
        });
    },
    async deleteFolder(key, silent = false) {
      const path = this.root.join("/") + "/folders";
      const folder = await this.getObjectSnapshot(path, key);
      console.log("Folder", folder);
      let promises = [];
      // Delete subfolders
      if ("folders" in folder) {
        for (const subfolder in folder.folders) {
          promises.push(...this.deleteFolder(subfolder, true));
        }
      }
      // Delete subitems (schemas, apis, files, data, etc.)
      if ("items" in folder) {
        Object.keys(folder.items).forEach((itemKey) => {
          promises.push(
            this.deleteObject(this.dbref + this.project.objectID, itemKey)
          );
          promises.push(
            this.deleteObject(path + "/" + key + "/items", itemKey)
          );
        });
      }
      // Delete folder
      promises.push(this.deleteObject(path, key));
      if (!silent) {
        Promise.all(promises)
          .then(() => {
            this.notyf.success("Folder deleted successfully.");
          })
          .catch((error) => {
            this.notyf.error("An error occurred deleting the folder.");
            console.error("Folder delete failed", key, error);
          });
      }
      return promises;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
}
</style>
