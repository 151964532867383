<template>
  <li :class="{ 'nav-item': true }">
    <a
      href="javascript:void(0)"
      :class="{ 'nav-link': true, active: tab.active, inactive: !tab.active }"
    >
      {{ tab.title }}<br />
      <small>{{ tab.description }}</small>
    </a>
  </li>
</template>
<script>
export default {
  name: "CustomWizardStep",
  props: {
    tab: {
      type: Object,
      default: () => {},
    },
    transition: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    stepCheckedStyle() {
      return {
        borderColor: this.tab.color,
      };
    },
    errorStyle() {
      return {
        borderColor: this.tab.errorColor,
        backgroundColor: this.tab.errorColor,
      };
    },
    stepTitleStyle() {
      let isError = this.tab.validationError;
      return {
        color: isError ? this.tab.errorColor : this.tab.color,
      };
    },
  },
};
</script>

<style lang="scss">
.sw.sw-justified > .nav .nav-link,
.sw.sw-justified > .nav > li {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.sw *,
.sw :after,
.sw :before {
  box-sizing: border-box;
}

// .wizard-primary.sw-theme-default > .nav .nav-link.active:after {
//   background: #3f80ea !important;
// }

// .sw-theme-default > .nav .nav-link.active:after {
//   width: 100%;
// }

// .sw-theme-default > .nav .nav-link:after {
//   content: "";
//   position: absolute;
//   height: 2px;
//   left: 0;
//   bottom: -1px;
//   transition: all 0.35s ease 0.15s;
// }
</style>
