<template>
  <b-container>
    <b-row>
      <b-col class="mt-3 w-100">
        <b-alert
          show
          variant="secondary"
          class="alert d-flex justify-content-left w-100"
        >
          <div class="alert-message">
            <b-link class="text-dark" @click="goHome"
              ><i class="fas fa-home"></i
            ></b-link>
            <span v-for="(breadcrumb, index) in path" :key="index">
              <i class="fas fa-angle-right mx-2 mt-1"></i>
              <b-link
                class="text-dark"
                @click="goToBreadcrumb(breadcrumb, index)"
                >{{ breadcrumb.name }}</b-link
              >
            </span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          hover
          striped
          responsive
          :fields="fields"
          :items="folderList"
          thead-class="d-none"
          class="mb-0"
        >
          <template #cell(icon)="data">
            <b-link @click="openFolder(data.item)">
              <i class="fas fa-folder"></i>
            </b-link>
          </template>
          <template #cell(name)="data">
            <b-link @click="openFolder(data.item)">
              {{ data.item.name }}
            </b-link>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-input
          class="path-output"
          :value="'Path: ' + pathString"
          disabled
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FirebaseMixin from "@/mixins/Firebase";

export default {
  name: "FolderSelector",
  mixins: [FirebaseMixin],
  props: {
    dbref: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    projectKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      folder: {},
      items: {},
      root: ["folders", "", ""],
      path: [],
      fields: [
        {
          key: "icon",
          label: "",
          sortable: false,
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    folderList() {
      if (this.folder) {
        return "folders" in this.folder
          ? Object.keys(this.folder.folders).map((folder) => {
              return {
                key: folder,
                ...this.folder.folders[folder],
              };
            })
          : [];
      } else {
        return [];
      }
    },
    topLevel() {
      return this.root.length === 3;
    },
    pathString() {
      let path = "/" + this.dbref;
      if (this.path.length > 0) {
        path +=
          "/" +
          this.path
            .map((breadcrumb) => {
              return breadcrumb.slug;
            })
            .join("/");
      }
      return path;
    },
  },
  watch: {
    root: {
      immediate: true,
      handler(root) {
        this.root[1] = this.dbref;
        this.bindObject(root.join("/"), null, "folder");
      },
    },
  },
  methods: {
    goHome() {
      this.root = ["folders", this.dbref, this.projectKey];
      this.path = [];
      this.$emit("input", this.pathString);
    },
    goBack() {
      this.root.splice(-2, 2);
      this.path.pop();
      this.$emit("input", this.pathString);
    },
    openFolder(folder) {
      console.log(folder);
      this.root.push("folders", folder.key);
      this.path.push({
        name: folder.name,
        slug: folder.slug,
        root: [...this.root],
      });
      this.$emit("input", this.pathString);
    },
    goToBreadcrumb(breadcrumb, index) {
      console.log(breadcrumb);
      this.root = breadcrumb.root;
      this.path.splice(index, this.path.length - index);
      this.$emit("input", this.pathString);
    },
    async setRootFromPath(routePath) {
      console.log("Route Path: " + routePath);
      const segments = routePath.split("/");
      let root = ["folders", this.dbref, this.projectKey];
      let path = [];
      for (const segment of segments) {
        const snapshot = await this.getObjectSnapshot(
          root.join("/"),
          null,
          true
        );
        const folder = snapshot.val();
        console.log("Folder", folder);
        if (folder && "folders" in folder) {
          Object.keys(folder.folders).forEach((subkey) => {
            const subfolder = folder.folders[subkey];
            if (subfolder.slug === segment.toLowerCase()) {
              root.push("folders", subkey);
              path.push({
                name: subfolder.name,
                slug: subfolder.slug,
                root: [...root],
              });
            }
          });
        }
      }
      this.root = [...root];
      this.path = [...path];
    },
  },
  mounted() {
    this.setRootFromPath(this.value);
  },
};
</script>

<style lang="scss" scoped>
.alert {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 0;
}
.alert-message {
  padding: 0.5rem 0.95rem;
}
.path-output {
  border-top: 1px solid #808080;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 0;
}
</style>
